a {
  color: #C8CDB9 !important;
}

/* .icons > a:first-child {
  color: #448790 !important;
} */

svg {
  padding: 0 !important;
}

.navbar {
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
  background-color: #1B2937;
  /* color: #C8CDB9; */
  margin-bottom: 50px;
}

.navbar-nav {
  width: 100%;
}

.nav-link:hover {
  /* background-color: #448790 !important; */
  color: #448790 !important;
}

.nav-link:active {
  color: #448790 !important;
}
