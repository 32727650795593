.custom-arrow-btn {
  color: #FD7C6F !important;
  /* background-color: #FD7C6F !important; */
}

.custom-arrow-btn-2 {
  color: #ECC7AD !important;
  /* background-color: #FD7C6F !important; */
}

.badge {
  color: #0d3d01 !important;
  /* background: #FD7C6F !important; */
}

.badge-hover {
  color: #bff080 !important;
}

.btn-primary, .btn-outline-primary, .btn-primary:active, .btn-primary:visited, .btn-primary:focus {
  background-color: #93d142 !important;
  border-color: transparent !important;
  color: #0d3d01 !important;
}

.btn-primary:hover {
  color: #bff080 !important;
}